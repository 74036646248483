import useTranslation from '../../useTranslation';
import { SharedComponent } from '../../../enums';

export function useMultiAuthText() {
  const { formatMessage, loading: textLoading } = useTranslation(
    SharedComponent.USE_MULTI_AUTH_TEXT
  );

  return {
    formatMessage,
    textLoading
  };
}
